(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/poker-react/views/start.js') >= 0) return;  svs.modules.push('/modules/poker-react/views/start.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.poker_react=_svs.poker_react||{};
_svs.poker_react.templates=_svs.poker_react.templates||{};
svs.poker_react.templates.start = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div id=\"poker-container\"></div>";
},"useData":true});
Handlebars.partials['svs-poker_react-start'] = svs.poker_react.templates.start;
})(svs, Handlebars);


 })(window);