(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/poker-react/assets/javascripts/route-components/route-news.js') >= 0) return;  svs.modules.push('/modules/poker-react/assets/javascripts/route-components/route-news.js');
"use strict";


const WidgetArea = svs.components.lbUtils.widgetArea.WidgetArea;
const {
  GametypeFoldingFan
} = svs.poker_react.components;
const {
  StaticWidgetArea
} = svs.poker_react.components.common.constants;
const {
  UpcomingEvents
} = svs.poker_react.components.upcomingEvents;
const {
  TournamentRegistrationForm
} = svs.poker_react.components.tournamentRegistrationForm;
const {
  useMediaQuery,
  breakpoints,
  BREAKPOINT_MD
} = svs.components.lbUtils.useMediaQuery;
const {
  ReactButton: Button,
  ReactAccordion: {
    Accordion,
    AccordionItem,
    AccordionSummary,
    AccordionDetails
  }
} = svs.ui;
const {
  launchWebClient
} = svs.poker_react.components.common.helpers;
const {
  detectedDevice,
  allDevices
} = svs.poker_react.data.clientDownloadLinks;
const clientDownloadHelp = svs.poker_react.data.clientDownloadHelp;
const RouteNews = () => {
  const isSmallDevice = useMediaQuery(breakpoints.down(BREAKPOINT_MD));
  const cards = svs.poker_react.data.foldingfanData.cards;
  const handleDownloadClick = (device, url) => {
    svs.components.analytics.trackEvent({
      category: 'Poker',
      action: 'Download client',
      opt_label: device
    });
    location.href = url;
  };
  const renderComponentsForDevices = () => {
    if (isSmallDevice) {
      return React.createElement(React.Fragment, null, React.createElement(UpcomingEvents, null), React.createElement(GametypeFoldingFan, {
        cards: cards
      }));
    }
    return (
      React.createElement(React.Fragment, null, React.createElement(GametypeFoldingFan, {
        cards: cards
      }), React.createElement(UpcomingEvents, null))
    );
  };
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "poker-welcome-section"
  }, React.createElement("h2", {
    className: "center-text poker-h2"
  }, "V\xE4lkommen till Svenska Spel Poker"), React.createElement("div", {
    className: "btn-container"
  }, detectedDevice && detectedDevice.showDevice && React.createElement(Button, {
    className: "btn-poker",
    onClick: () => handleDownloadClick(detectedDevice.device, detectedDevice.url),
    transparent: true
  }, detectedDevice.text), React.createElement(Button, {
    className: "btn-poker",
    onClick: () => launchWebClient(),
    transparent: true
  }, "Spela direkt"))), renderComponentsForDevices(), React.createElement(WidgetArea, {
    widgetArea: StaticWidgetArea.News
  }), React.createElement("div", {
    className: "tournament-section"
  }, React.createElement("h3", {
    className: "center-text h2"
  }, "Boka turnering"), React.createElement(TournamentRegistrationForm, null)), clientDownloadHelp && React.createElement(Accordion, {
    className: "client-links-accordion"
  }, React.createElement(AccordionItem, {
    className: "client-links-accordion-details"
  }, React.createElement(AccordionSummary, null, React.createElement("h2", {
    className: "f-content h2"
  }, clientDownloadHelp.header)), React.createElement(AccordionDetails, null, React.createElement("p", null, clientDownloadHelp.descriptionText), React.createElement("p", null, "H\xE4r kan du v\xE4lja att ladda ner den klient som just du beh\xF6ver"), React.createElement("ul", null, allDevices.map(_ref => {
    let {
      device,
      url,
      text
    } = _ref;
    return React.createElement("li", {
      key: device
    }, React.createElement("a", {
      className: "f-bold",
      onClick: () => handleDownloadClick(device, url)
    }, text));
  }))))), React.createElement(WidgetArea, {
    widgetArea: StaticWidgetArea.SEO_Optimization
  }));
};
setGlobal('svs.poker_react.routeComponents.RouteNews', RouteNews);

 })(window);