(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/poker-react/assets/javascripts/route-components/route-widget.js') >= 0) return;  svs.modules.push('/modules/poker-react/assets/javascripts/route-components/route-widget.js');
"use strict";


const WidgetArea = svs.components.lbUtils.widgetArea.WidgetArea;
const {
  widgetAreaPrefix
} = svs.poker_react.components.common.constants;
const RouteWidget = _ref => {
  let {
    widgetAreaIndex
  } = _ref;
  if (widgetAreaIndex < 1) {
    return null;
  }
  const widgetArea = "".concat(widgetAreaPrefix, "_").concat(widgetAreaIndex);
  return React.createElement(WidgetArea, {
    widgetArea: widgetArea
  });
};
setGlobal('svs.poker_react.routeComponents.RouteWidget', RouteWidget);

 })(window);